import React, { useCallback, useEffect, useState } from "react";
import CreateOrUpdateModal from "./CreateOrUpdateModal";
import useAPI from "shared/hooks/useApi";
import I18n from "shared/lib/I18n";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
    registration_is_client: yup.boolean().required("utils.form.required"),
    registration_type: yup
        .string()
        .nullable()
        .trim()
        .required("utils.form.required")
        .transform((value) => (value !== "" ? value : null)),
    firstname: yup.string().trim().required("utils.form.required"),
    lastname: yup.string().trim().required("utils.form.required"),
    email: yup
        .string()
        .trim()
        .required("utils.form.required")
        .email("utils.form.email"),
    registration_company: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null)),
    registration_artist: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null)),
    registration_role: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null)),
    registration_describe: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null)),
    registration_language: yup
        .string()
        .nullable()
        .trim()
        .transform((value) => (value !== "" ? value : null)),
    api_limit: yup.number().typeError("utils.form.number"),
    id_image_server: yup.number().nullable().required("utils.form.required"),
    token: yup
        .string()
        .nullable()
        .transform((value) => (value !== "" ? value : null)),
});

const defaultValues = {
    registration_is_client: false,
    registration_type: null,
    firstname: null,
    lastname: null,
    email: null,
    registration_company: null,
    registration_role: null,
    registration_describe: null,
    registration_language: null,
    registration_artist: null,
    user_roles: [],
    label: null,
    api_limit: 200,
    id_image_server: null,
    author: null,
    expires_at: null,
    token: null,
};

const EnhancedCreateOrUpdateModal = ({
    show,
    handleClose,
    user,
    onSuccess,
    rolesOptions,
    ...props
}) => {
    const location = useLocation();
    const { locale } = useParams();
    const { api } = useAPI();
    const {
        handleSubmit,
        register,
        reset,
        control,
        watch,
        setValue,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues,
    });

    const accountTypeWatch = watch("registration_type");

    useEffect(() => {
        reset(user);
    }, [reset, user, show]);

    const generateToken = () => {
        api.get(`users/generate/token`)
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `user.manage.generate_token.success`
                    )
                );
                setValue("token", response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `user.manage.generate_token.error`,
                        error.message
                    )
                );
            });
    };

    const onSubmit = (data) => {
        let apiRequest;
        if (user?.id) {
            apiRequest = api.put(
                `users/${user.id}`,
                { locale },
                { id: user.id, ...data }
            );
        } else {
            apiRequest = api.post(`users`, { locale }, data);
        }
        apiRequest
            .then((response) => {
                toast.success(
                    I18n.getTranslation(
                        location,
                        `user.manage.${user?.id ? "update" : "create"}.success`
                    )
                );
                handleClose();
                onSuccess?.();
            })
            .catch((error) => {
                console.error(error);
                toast.error(
                    I18n.getTranslation(
                        location,
                        `user.manage.${user?.id ? "update" : "create"}.error`,
                        error.message
                    )
                );
            });
    };

    const accountTypeOptions = [
        {
            label: I18n.getTranslation(
                location,
                "user.manage.user.account_type.music_service"
            ),
            value: "music_service",
        },
        {
            label: I18n.getTranslation(
                location,
                "user.manage.user.account_type.cmo"
            ),
            value: "cmo",
        },
        {
            label: I18n.getTranslation(
                location,
                "user.manage.user.account_type.label_distributor"
            ),
            value: "label_distributor",
        },
        {
            label: I18n.getTranslation(
                location,
                "user.manage.user.account_type.artist_manager"
            ),
            value: "artist_manager",
        },
    ];

    const accountRoleOptions = {
        music_service: [
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.music_editor"
                ),
                value: "music_editor",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.marketing"
                ),
                value: "marketing",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.catalog_management"
                ),
                value: "catalog_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.product_management"
                ),
                value: "product_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.other"
                ),
                value: "other",
            },
        ],
        label_distributor: [
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.catalog_management"
                ),
                value: "catalog_management",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.promotion"
                ),
                value: "promotion",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.business_development"
                ),
                value: "business_development",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.a_and_r"
                ),
                value: "a_and_r",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.supply_chain"
                ),
                value: "supply_chain",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.analytics"
                ),
                value: "analytics",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.other"
                ),
                value: "other",
            },
        ],
        cmo: [
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.tech"
                ),
                value: "tech",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.executive"
                ),
                value: "executive",
            },
            {
                label: I18n.getTranslation(
                    location,
                    "user.manage.user.role.catalog_management"
                ),
                value: "catalog_management",
            },
        ],
    };

    const accountLanguageOptions = [
        {
            label: I18n.getTranslation(location, "utils.lang.FR"),
            value: "fr",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.EN"),
            value: "en",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.DE"),
            value: "de",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.JA"),
            value: "ja",
        },
        {
            label: I18n.getTranslation(location, "utils.lang.KO"),
            value: "ko",
        },
    ];

    const [serverOptions, setServerOptions] = useState([]);
    const getImageServer = useCallback(() => {
        api.get(`image-server`, { locale })
            .then((response) => {
                setServerOptions(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [locale]);

    useEffect(getImageServer, [getImageServer]);

    return (
        <CreateOrUpdateModal
            {...props}
            show={show}
            user={user}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            location={location}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            isSubmitting={isSubmitting}
            control={control}
            accountTypeOptions={accountTypeOptions}
            accountRoleOptions={accountRoleOptions}
            accountLanguageOptions={accountLanguageOptions}
            serverOptions={serverOptions}
            rolesOptions={rolesOptions}
            accountTypeWatch={accountTypeWatch}
            generateToken={generateToken}
            locale={locale}
        />
    );
};

export default EnhancedCreateOrUpdateModal;
